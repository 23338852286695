import { Elm } from "../src/Main.elm";
import version from "./version.json";

var app = Elm.Main.init({
    node: document.getElementById("elm"),
    flags: {
        window: { width: window.innerWidth, height: window.innerHeight },
        time: { millis: Date.now() },
        clientVersion: version
    }
});

app.ports.copyToClipboard.subscribe(function (data) {
    navigator.clipboard.writeText(data).then(
        function () {
            // success 
        })
        .catch(
            // failure
            console.log("Unable to copy string to clipboard"))
        ;
}
);

app.ports.scrollIntoView.subscribe(function (id) {
    window.requestAnimationFrame(function (timestamp) {
        var element = document.getElementById(id)
        if (!element) { return }
        element.scrollIntoView()
    })
});
