module Main exposing (..)

import Browser
import Browser.Navigation as Nav
import Data exposing (ClientVersion)
import Effect exposing (Effect)
import Html
import Json.Decode as Decode
import Page
import Route
import Shared exposing (Shared)
import Time
import Url


type alias Model =
    { navKey : Nav.Key
    , shared : Shared
    , page : Page.Model
    }


type Msg
    = PageMsg Page.Msg
    | UrlRequest Browser.UrlRequest
    | UrlChange Url.Url
    | SharedMsg Shared.Msg


main =
    Browser.application
        { init =
            \flags url navKey ->
                init flags url navKey |> applyEffect
        , view = view
        , update =
            \msg model ->
                update msg model
                    |> applyEffect
        , subscriptions = subscriptions
        , onUrlChange = UrlChange
        , onUrlRequest = UrlRequest
        }


applyEffect ( model, effect ) =
    let
        config =
            { navKey = model.navKey, clientOutdated = Shared.clientOutdated model.shared }
    in
    ( model, Effect.applyEffect (Shared.pageToUrl model.shared) config effect )


init : Decode.Value -> Url.Url -> Nav.Key -> ( Model, Effect Msg )
init flagsValue url navKey =
    let
        flags =
            Decode.decodeValue Shared.flagsDecoder flagsValue
                |> Result.withDefault
                    { window = { width = 640, height = 480 }
                    , time = Time.millisToPosix 0
                    , clientVersion = ClientVersion (Time.millisToPosix 0)
                    }

        route =
            Route.fromUrl url |> Maybe.withDefault Route.Landing

        ( initShared, initSharedEffect ) =
            Shared.init flags url

        ( page, effect, shared ) =
            Page.init initShared route |> applyShared
    in
    ( { navKey = navKey
      , page = page
      , shared = shared
      }
    , Effect.batch [ Effect.map SharedMsg initSharedEffect, effect ]
    )


view : Model -> Browser.Document Msg
view model =
    Page.view model.shared model.page |> (\doc -> { title = doc.title, body = List.map (Html.map PageMsg) doc.body })


applyShared : ( Page.Model, Effect Page.Msg, Shared ) -> ( Page.Model, Effect Msg, Shared )
applyShared ( page, effect, sharedWithQueue ) =
    let
        ( shared, sharedEffectQueue ) =
            Shared.extractEffectQueue sharedWithQueue
    in
    ( page
    , Effect.batch
        [ Effect.map PageMsg effect
        , Effect.map SharedMsg (Effect.batch sharedEffectQueue)
        ]
    , shared
    )


update : Msg -> Model -> ( Model, Effect Msg )
update msg model =
    case msg of
        PageMsg pageMsg ->
            let
                ( page, effect, shared ) =
                    Page.update model.shared pageMsg model.page |> applyShared
            in
            ( { model | page = page, shared = shared }, effect )

        UrlChange url ->
            let
                route =
                    Route.fromUrl url |> Maybe.withDefault Route.Landing

                ( page, effect, shared ) =
                    Page.urlChanged model.shared route model.page |> applyShared
            in
            ( { model | page = page, shared = shared }, effect )

        UrlRequest urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    let
                        page =
                            Route.fromUrl url |> Maybe.withDefault (Route.Login { onLogin = Nothing })
                    in
                    ( model
                    , Effect.NavPush page
                    )

                Browser.External urlString ->
                    ( model
                    , Effect.NavLoad urlString
                    )

        SharedMsg sm ->
            let
                ( updatedShared, sharedEffect, maybeEvent ) =
                    Shared.update sm model.shared

                ( page, effect, shared ) =
                    maybeEvent
                        |> Maybe.map
                            (\event ->
                                Page.sharedEvent updatedShared event model.page
                            )
                        |> Maybe.withDefault
                            ( model.page, Effect.none, updatedShared )
                        |> applyShared
            in
            ( { model | shared = shared, page = page }
            , Effect.batch
                [ Effect.map SharedMsg sharedEffect
                , effect
                ]
            )


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ Shared.subscriptions model.shared |> Sub.map SharedMsg
        , Page.subscriptions model.shared model.page |> Sub.map PageMsg
        ]
